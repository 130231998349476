import { useState } from "react";
import { ActionMenu } from "../../components/ActionMenu.tsx";
import { Badge } from "../../components/Badge.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WSearchField } from "../../components/forms/WSearchField.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { TODO } from "../../dev/Todo.tsx";
import { todoAction } from "../../dev/todoSubmit.ts";
import { AdminTitle } from "../AdminTitle.tsx";

export function BaremetalView() {
    const [queryString, setQueryString] = useState("");

    const items = [
        {
            id: "uuid",
            title: "UUID",
        },
    ];

    return (
        <>
            <AdminTitle title="Baremetal">
                <WModal button={<WModalButton label="New" color="primary" size="bar" variant="basic" />}>
                    <WModalContent title="Add Baremetal" label="Add" modalAction={todoAction}>
                        <TODO />
                    </WModalContent>
                </WModal>
            </AdminTitle>

            <WTable adminTable search={<WSearchField value={queryString} onChange={setQueryString} />}>
                <thead>
                    <tr>
                        <th>UUID</th>
                        <th>Created At</th>
                        <th>Name</th>
                        <th>Details</th>
                        <th>OS</th>
                        <th>IP</th>
                        <th>Access</th>
                        <th>Pool UUID</th>
                        <th>Pool Name</th>
                        <th>Status</th>
                        <th>Billing</th>
                        <th className="text-right">
                            <ActionMenu header="Filter" onAction={todoAction} selectedKeys={[]} items={items}>
                                <WButton
                                    color="muted"
                                    variant="ghost"
                                    size="xs"
                                    icon="jp-icon-toggles size-0.75rem"
                                    ariaLabel="Filter"
                                    action={undefined}
                                />
                            </ActionMenu>
                        </th>
                    </tr>
                </thead>
                <WTableBody>
                    <tr>
                        <td>
                            <div className="flex items-center gap-0.375em">
                                <MaskIcon className="jp-host-icon color-primary size-0.75rem" />
                                <div>34545745863568356745...</div>
                            </div>
                        </td>
                        <td>16 Oct 2023 16:06 UTC</td>
                        <td>Machine 1</td>
                        <td>AMD 9124, 16 C...</td>
                        <td>Ubuntu 16.3</td>
                        <td>192.168.123.31</td>
                        <td>SSH details</td>
                        <td>45e345egdfsdfghfds</td>
                        <td>AI Metal</td>
                        <td>
                            <Badge color="success">In use</Badge>
                        </td>
                        <td>BA nimi</td>
                        <td className="text-right">
                            <WModal button={<WModalButton label="Edit" inTable icon="jp-icon-edit" />}>
                                <WModalContent title="Edit Baremetal Pool" label="Edit" modalAction={todoAction}>
                                    <TODO />
                                </WModalContent>
                            </WModal>

                            <DeleteModal title="Delete Baremetal Pool" inTable modalAction={todoAction}>
                                <p>Are you sure you want to delete baremetal pool?</p>
                            </DeleteModal>
                        </td>
                    </tr>
                </WTableBody>
            </WTable>

            <div className="p-2">
                <WModal
                    button={
                        <WModalButton
                            className="w-full"
                            label="Add Baremetal"
                            color="primary"
                            size="lg"
                            variant="dashed"
                            icon="jp-icon-add"
                        />
                    }
                >
                    <WModalContent title="Add Baremetal" label="Add" modalAction={todoAction}>
                        <TODO />
                    </WModalContent>
                </WModal>
            </div>
        </>
    );
}
